import api from './api'

export default {
    add(params) {
        return api.post('currencies', params)
    },
    async get(id) {
        const { data } = await api.fetch(`currencies/${id}`)
        return data
    },
    async list(params) {
        const { data } = await api.fetch('/currencies', params)
        return data
    },
    update(params) {
        return api.patch('currencies', params)
    },
    delete(id) {
        return api.remove('currencies', id)
    },
}