import { ICurrency } from '~/interfaces/currency'
import currency from '~/api/currencies';

const dataShopCurrencies: ICurrency[] = []

async function loadCurrencies() {

    const response: any = await currency.list({
        'page[size]': 1000,
        status: 'O',
    });

    if (response) {
        response.forEach((e: any) => {
            dataShopCurrencies.push({
                code: e.cur_desc,
                symbol: '£',
                name: e.cur_desc,
            });

        });
    }
}
loadCurrencies();
export default dataShopCurrencies
